import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html'
})
export class BasicLayoutComponent implements OnInit {
  language = localStorage.getItem('language') || 'en';

  constructor() {
  }

  ngOnInit() {
  }

  changeLanguage() {
    if (this.language === 'en') {
      localStorage.setItem('language', 'ar');
    } else {
      localStorage.setItem('language', 'en');
    }
    window.location.reload();
  }

}
