import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from 'src/app/features/auth/services/auth.service';
import {CreateFormComponent} from 'src/app/shared/components/create-form/create-form.component';
import {CabListFilterModel} from '../../../features/cabs/classes/CabListFilterModel';
import {CabListSortList} from '../../../features/cabs/enum/cabListSortList.enum';
import {CabsListService} from '../../../features/cabs/service/cabs-list.service';

@Component({
  selector: 'app-cab-list',
  templateUrl: './cab-list.component.html',
  styles: [],
})
export class CabListComponent implements OnInit {
  dataLoading = false;
  showFilter = false;
  showSearch = false;
  sortItemsSelected = CabListSortList.Name;
  userToken;
  showNav = true;
  sortItems = [
    {
      name: 'Name',
      value: CabListSortList.Name,
    },
    {
      name: 'Created At',
      value: CabListSortList.CreatedAt,
    },
  ];
  statusList = [];

  // table
  pageCount = 0;
  pageIndex = 0;
  recordsData: any = {};
  showDirectPublish: any;

  cabListFilterModel: CabListFilterModel = new CabListFilterModel();

  constructor(
    public dialog: MatDialog,
    private cabsListService: CabsListService,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.userToken = params.get('id');
        this.showNav = false
      } else {
        this.userToken = JSON.parse(localStorage.getItem('userToken'));
        this.showNav = true
      }
    });
    this.cabListFilterModel.per_page = 10;
    this.getAll(null);
    // this.getCountries();
  }

  getAll(event) {
    this.dataLoading = true;
    this.cabListFilterModel.user_token = this.userToken;
    if (event) {
      this.pageIndex = event.pageIndex + 1;
      this.cabListFilterModel.page_number = this.pageIndex;
      this.cabListFilterModel.per_page = event.pageSize;
    } else {
      this.cabListFilterModel.page_number = 1;
    }

    this.cabsListService.getAllCABsList(this.cabListFilterModel).subscribe(
      (data) => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data.data;
          this.showDirectPublish = data.show_direct_publish;
          this.pageCount = data.count_items;
          this.pageIndex = this.pageIndex - 1;
        }
      },
      (error) => {
        this.dataLoading = false;
      }
    );
  }

  sortRecords(selected) {
    this.cabListFilterModel.order_by = Number(selected.value);
    this.getAll(null);
  }

  // SEARCH
  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.cabListFilterModel.search_key) {
        this.getAll(null);
      }
    }
  }

  makeFilter() {
    this.getAll(null);
  }

  resetAfterClear() {
    if (!this.cabListFilterModel.search_key) {
      this.resetSearch();
    }
  }

  resetSearch() {
    this.cabListFilterModel.search_key = '';
    this.getAll(null);
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.cabListFilterModel.page_number = event.target.value - 1;
      this.getAll(null);
    }
  }

  initName(name) {
    if (!name) {
      return '';
    }
    let initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || '') + (initials.pop() || '')
    ).toUpperCase();
    return initials;
  }

  openCreateDialog() {
    const dialogRef = this.dialog.open(CreateFormComponent, {
      width: '450px',
      data: {title: 'Add New CAB'},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAll(null);
      }
    });
  }

  openDirectPublish() {
    this.router.navigate(['/admin/CABs/directPublishForm']);
  }

  navigateToProfile(token) {
    this.router.navigate(['/admin/CABs/CABsProfile/', token]);
  }

  showError(msg) {
    this.toastr.error(msg);
  }

  showSuccess(msg) {
    this.toastr.success(msg);
  }
}
