<section class="create payment">
  <div class="create_title">Upload File</div>
  <div cdkFocusInitial class="close-dialog" mat-dialog-close>
    <svg
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
        data-name="Path 7160"
        fill="#0c2e56"
        id="Path_7160"
        transform="translate(-5 -5)"
      />
    </svg>
  </div>
  <mat-dialog-content>
    <div class="create_content">
      <div class="row">
        <div class="col-sm-12">
          <app-file-manager
            (deletedFilesList)="deletedFiles = $event"
            (selectedFiles)="attachedFiles = $event"
            [files]="files"
            [isSubmited]="isSubmitted"
            [managerIndex]="'wordFile'"
            [maxFiles]="1"
          ></app-file-manager>
        </div>
        <div class="col-sm-12 mt-5">
          <div class="btn-container">
            <button
              (click)="onSubmit()"
              [class.loading]="loading"
              class="btn-create w-100"
              color="primary"
              mat-raised-button
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</section>
