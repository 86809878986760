<div class="confirm">
  <div class="confirm_title">
    <h1 mat-dialog-title>
      {{ title }}
    </h1>
  </div>
  <div class="confirm_img">
    <mat-icon>info</mat-icon>
  </div>
  <div class="confirm_msg text-center">
    <div mat-dialog-content>
      <p>{{ message }}</p>
    </div>
  </div>
  <div class="confirm_action">
    <button (click)="onDismiss()" class="mx-2" mat-button>No</button>
    <button
      (click)="onConfirm()"
      class="mx-2"
      color="primary"
      mat-raised-button
    >
      Yes
    </button>
  </div>
</div>
