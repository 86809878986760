import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent implements OnInit {
  toggleSide = false;
  menuLoading = true;

  constructor() {
  }

  ngOnInit() {
  }
}
