import {AbstractControl} from '@angular/forms';

export const whiteSpaceValidator = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  if (!control.value.length) {
    return null;
  }
  const isWhitespace = control.value.trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : {whiteSpaceValidator: true};
};
