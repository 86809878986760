import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationsService} from './services/notifications.service';

@NgModule({
  declarations: [],
  providers: [NotificationsService],
  imports: [CommonModule],
})
export class NotificationsModule {
}
