import {Component, Input} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE,} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter,} from '@angular/material-moment-adapter';

// tslint:disable-next-line:no-duplicate-imports
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import {FormControl} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'YY',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-custom-year-picker',
  templateUrl: './custom-year-picker.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CustomYearPickerComponent {
  @Input() isRequired = false;
  date = new FormControl();
  maxDate = new Date('01/1/2050');
  minDate = new Date();
  // matDatepickerFilter = ['01/1/2023' , '01/1/2025']

  disabledYears = [
    new Date('12/1/2020'),
    new Date('12/20/2023'),
    new Date('12/17/2025'),
    new Date('12/25/2029'),
  ];

  onYearsFilter = (d: Date): boolean => {
    const date = new Date(d);
    const time = date.getFullYear();
    return !this.disabledYears.find((x) => x.getFullYear() == time);
  };

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  // chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
  //   const ctrlValue = this.date.value;
  //   ctrlValue.month(normalizedMonth.month());
  //   this.date.setValue(ctrlValue);
  //   datepicker.close();
  //  HTML >>>  (monthSelected)="chosenMonthHandler($event, dp)"
  // }
}
