import {Injectable} from '@angular/core';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ApiService} from 'src/app/core/service/api.service';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AllTrainingListService {
  constructor(private apiService: ApiService) {
  }

  getAllTrainingList(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_all_training`, model)
      .pipe(catchError(this.handleError));
  }

  getPlannedYears() {
    return this.apiService
      .get(`${environment.apiUrl}/api/system/get_plans`)
      .pipe(catchError(this.handleError));
  }

  getTrainingPlan(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/get_training_plans`, model)
      .pipe(catchError(this.handleError));
  }

  createTrainingCourse(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_course`, model)
      .pipe(catchError(this.handleError));
  }

  editTrainingCourse(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/edit_course`, model)
      .pipe(catchError(this.handleError));
  }

  traineePayment(model) {
    const formData = new FormData();
    formData.append('course_token', model.course_token);
    formData.append('payment_option', model.payment_option);
    if (model.attached_file) {
      model.attached_file.forEach((element) => {
        if (element.type) {
          formData.append('attached_file', element, element.name);
        }
      });
    }
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/trainee_pay_course_fees`,
        formData
      )
      .pipe(catchError(this.handleError));
  }

  traineePaymentForFree(model) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/training_course_enrollment`,
        model
      )
      .pipe(catchError(this.handleError));
  }

  trainneeApply(model) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/training_course_enrollment`,
        model
      )
      .pipe(catchError(this.handleError));
  }

  allPaymentRequests(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_all_training_payment`, model)
      .pipe(catchError(this.handleError));
  }

  getCurrencyList(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/get_currency_list`, data)
      .pipe(catchError(this.handleError));
  }

  handleError(error) {
    return throwError(error);
  }
}
