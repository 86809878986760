<nav class="navbar head-nav" *ngIf="showNav">
  <div>
    <h2>CABs</h2>
  </div>
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a>Home</a>
    </li>
    <li class="breadcrumb-item">CABs</li>
  </ol>
</nav>
<div class="page">
  <div class="page-filters">
    <div class="count-item">
      <span class="counter"> {{ pageCount }} </span>
      <span class="title">CABs</span>
    </div>
    <div class="sort-item">
      <label>Sorted By:</label>
      <mat-form-field appearance="outline" class="sort-filter">
        <mat-select
          (selectionChange)="sortRecords($event)"
          [(value)]="sortItemsSelected"
        >
          <mat-option
            *ngFor="let sortItem of sortItems"
            [value]="sortItem.value"
          >
            {{ sortItem.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="filters-container">
      <form (submit)="makeSearch()" class="search-input">
        <mat-form-field *ngIf="showSearch" appearance="legacy">
          <input
            (ngModelChange)="resetAfterClear()"
            [(ngModel)]="cabListFilterModel.search_key"
            [ngModelOptions]="{ standalone: true }"
            autocomplete="off"
            matInput
          />
          <mat-icon (click)="resetSearch(); showSearch = false" matSuffix
          >clear
          </mat-icon
          >
        </mat-form-field>
        <button (click)="makeSearch()" mat-icon-button>
          <mat-icon>search</mat-icon>
        </button>
      </form>
      <!-- <button
        (click)="showFilter = !showFilter"
        [ngClass]="{ active: showFilter }"
        class="btn dropdown-custom dropdown-toggle"
      >
        <img alt="" src="../../../../../assets/images/icons/filter-icon.svg"/>
        <span>Filter</span>
        <svg height="5.001" viewBox="0 0 8 5.001" width="8">
          <path
            d="M-3.562-9.316-.5-6.215l3.062-3.1.938.949-4,4.051-4-4.051Z"
            fill="#6a6a6a"
            id="menu-icon"
            opacity="0.8"
            transform="translate(4.5 9.316)"
          />
        </svg>
      </button> -->
      <div class="create-item">
        <button
          (click)="openCreateDialog()"
          *ngIf="false"
          class="btn customBtn mr-2"
          color="primary"
          mat-raised-button
        >
          <span> Add New CAB</span>
        </button>
        <button
          (click)="openDirectPublish()"
          *ngIf="showDirectPublish"
          class="btn customBtn"
          color="primary"
          mat-raised-button
        >
          <span> Direct Publish</span>
        </button>
      </div>
    </div>
    <div [hidden]="!showFilter" class="filter-section">
      <div class="row">
        <div class="col-xl-2 col-lg-4 mb-2">
          <!-- <mat-form-field>
            <mat-label>From Date</mat-label>
            <input
              matInput
              [min]=""
              [max]=""
              [(ngModel)]="cabListFilterModel.from_date"
              (ngModelChange)="makeFilter()"
              [matDatepicker]="expirationDateFrom"
              readonly
            />
            <button
              mat-icon-button
              class="clear-date"
              *ngIf="cabListFilterModel.from_date !== undefined"
              (click)="cabListFilterModel.from_date = undefined; getAll(null)"
            >
              <mat-icon>clear</mat-icon>
            </button>
            <mat-datepicker-toggle
              matSuffix
              [for]="expirationDateFrom"
            ></mat-datepicker-toggle>
            <mat-datepicker #expirationDateFrom></mat-datepicker>
          </mat-form-field> -->
        </div>
        <div class="col-xl-2 col-lg-4 mb-2">
          <!-- <mat-form-field>
            <mat-label>To Date</mat-label>
            <input
              matInput
              [min]=""
              [max]=""
              [(ngModel)]="cabListFilterModel.to_date"
              (ngModelChange)="makeFilter()"
              [matDatepicker]="expirationDateTo"
              readonly
            />
            <button
              mat-icon-button
              class="clear-date"
              *ngIf="cabListFilterModel.to_date !== undefined"
              (click)="cabListFilterModel.to_date = undefined; getAll(null)"
            >
              <mat-icon>clear</mat-icon>
            </button>
            <mat-datepicker-toggle
              matSuffix
              [for]="expirationDateTo"
            ></mat-datepicker-toggle>
            <mat-datepicker #expirationDateTo></mat-datepicker>
          </mat-form-field> -->
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="dataLoading" class="dataLoading">
    <mat-spinner class="m-auto" diameter="50"></mat-spinner>
  </div>
  <ng-container *ngIf="!dataLoading">
    <div *ngIf="recordsData; else dataEmpty">
      <div *ngIf="recordsData.length; else dataEmpty" class="table-responsive">
        <table class="table table-primary">
          <thead>
          <tr>
            <th width="1%"></th>
            <th width="30%">CAB Name</th>
            <th width="1%"></th>
            <th width="20%">CAB Admin</th>
            <th width="20%">Email</th>
            <th width="20%">Created On</th>
            <th width="9%"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let record of recordsData">
            <td>
              <div class="img-cricle">
                <div class="image m-auto">
                  <img
                    (error)="record.cab_logo = ''"
                    *ngIf="record.cab_logo"
                    [src]="record.cab_logo"
                  />
                  <div
                    *ngIf="!record.cab_logo"
                    [innerHTML]="record.cab_name_en | shortName"
                  ></div>
                </div>
              </div>
            </td>
            <td>
              <div class="cab-en">
                {{ record.cab_name_en }}
              </div>
              <div class="cab-ar">
                {{ record.cab_name_ar }}
              </div>
            </td>
            <td>
              <div class="img-cricle">
                <div class="image m-auto">
                  <img
                    (error)="record.cab_admin_logo = ''"
                    *ngIf="record.cab_admin_logo"
                    [src]="record.cab_admin_logo"
                  />
                  <div
                    *ngIf="!record.cab_admin_logo"
                    [innerHTML]="record.cab_name | shortName"
                  ></div>
                </div>
              </div>
            </td>
            <td>
              <div class="cab-en">
                {{ record.cab_name }}
              </div>
            </td>
            <td>
              <span class="link"> {{ record.cab_email }}</span>
            </td>
            <td>
              <span> {{ record.cab_created_at | unixDate }}</span>
            </td>
            <td>
              <div class="btn-actions">
                <a
                  (click)="navigateToProfile(record.baisc_token)"
                  mat-icon-button
                  matTooltip="View"
                >
                  <mat-icon>visibility</mat-icon>
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="egac-paginator">
        <mat-paginator
          (page)="getAll($event)"
          [length]="pageCount"
          [pageIndex]="pageIndex"
          [pageSizeOptions]="[5, 10, 15, 20]"
          [pageSize]="cabListFilterModel.per_page"
        ></mat-paginator>
      </div>
    </div>
  </ng-container>
  <ng-template #dataEmpty>
    <div class="data-empty">
      <p>No Data Found</p>
    </div>
  </ng-template>
</div>
