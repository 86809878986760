import {AuthService} from './../../../features/auth/services/auth.service';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild,} from '@angular/core';
import {Router} from '@angular/router';
import {LookupsService} from 'src/app/core/service/lookup.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SideBarComponent implements OnInit {
  @ViewChild('menu') menu: ElementRef;
  @Input() toggleSide;
  @Output() sidebarTrigger = new EventEmitter();
  @Output() menuLoading = new EventEmitter();
  menuItems: any[] = [];
  // shouldOpenMenu = false;
  openRequestsSub = false;
  openProfileRequestsSub = false;
  openConfigSub = false;
  openInnerSub = false;
  unseenMessages: any;
  childParents: any = [];
  tokenArray = [];
  currentUser;

  constructor(
    private lookupsService: LookupsService,
    private authService: AuthService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {
    this.currentUser = this.authService.currentUserValue;
    this.onGetMenu();
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target == this.menu.nativeElement) {
        this.sidebarTrigger.emit(true);
      }
    });
  }

  ngOnInit() {
  }

  closeSubMenus(e) {
    // this.menuItems.forEach((item) => {
    //   item.openSub = false;
    // });
    // e.openSub = !e.openSub;
    // console.log(window.document.getElementsByClassName('open-sidebar'));
    const menuIsOpenFromBurgerMenu =
      window.document.getElementsByClassName('open-sidebar');
    if (menuIsOpenFromBurgerMenu.length) {
      if (e.openSub) {
        this.menuItems.forEach((item) => {
          item.openSub = false;
        });
      } else {
        this.menuItems.forEach((item) => {
          item.openSub = false;
        });
        e.openSub = true;
      }
    } else {
      if (window.innerWidth > 1300) {
        if (e.openSub) {
          this.menuItems.forEach((item) => {
            item.openSub = false;
          });
        } else {
          this.menuItems.forEach((item) => {
            item.openSub = false;
          });
          e.openSub = true;
        }
      }
    }
  }

  onGetMenu() {
    this.lookupsService.getMenu().subscribe(
      (result) => {
        this.menuItems = result.data;
        this.menuItems.map((item) => {
          item.openSub = false;
          this.childParents.push(item.childParent);
        });

        // access user links
        const allowedMenu = [];
        this.menuItems.forEach((link) => {
          allowedMenu.push(link.url);
        });
        this.closeSubMenus;

        const obj = this.menuItems.find(
          (item) =>
            item.childParent.token === 'N3AXwEgywR11lIZr9twRKdZTl0YunB'
        );

        for (let i = 0; i < this.childParents.length; i++) {
          const firstItem = this.childParents[i];
          for (let x = 0; x < firstItem.length; x++) {
            const token = firstItem[x].token;
            this.tokenArray.push(token);
          }
        }
        this.authService.currentPermissions.next(this.tokenArray);
        this.menuLoading.emit(false);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  initName(name) {
    if (!name) {
      return '';
    }
    let initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || '') + (initials.pop() || '')
    ).toUpperCase();
    return initials;
  }

  sanitizerSVG(svg) {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }
}
