import {Injectable} from '@angular/core';
import {BehaviorSubject, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ApiService} from 'src/app/core/service/api.service';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GroupTrainingRequestService {
  refreshDetails = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService) {
  }

  getAll(filter: any) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/list_all_group_training_request`,
        filter
      )
      .pipe(catchError(this.handleError));
  }

  getRequestDetails(data) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/group_training_request_details`,
        data
      )
      .pipe(catchError(this.handleError));
  }

  requestGroupTraining(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_training_request`, data)
      .pipe(catchError(this.handleError));
  }

  declineRequest(data) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/tm_decline_group_training_request`,
        data
      )
      .pipe(catchError(this.handleError));
  }

  addGroupTraining(data) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/approve_training_request`, data)
      .pipe(catchError(this.handleError));
  }

  handleError(error) {
    return throwError(error);
  }
}
