import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import {AllTrainingListService} from 'src/app/features/training/services/all-training-list.service';

@Component({
  templateUrl: './upload-proof.component.html',
  styles: [],
})
export class UploadProofComponent implements OnInit {
  loading = false;
  files = [];
  deletedFiles: any[] = [];
  attachedFiles: any[] = [];
  isSubmitted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadProofComponent>,
    private allTrainingListService: AllTrainingListService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.isSubmitted = true;
    this.loading = true;
    const model = {
      course_token: this.data.courseToken,
      payment_option: 1,
      attached_file: this.files,
    };
    this.allTrainingListService.traineePayment(model).subscribe(
      (res) => {
        this.dialogRef.close(true);
        this.loading = false;
      },
      (err) => {
        // console.log(err);
        this.toastr.error(err.message);
        this.loading = false;
      }
    );
  }
}
