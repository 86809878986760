import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-confirmation-remove-role',
  templateUrl: './confirmation-remove-role.component.html',
  styles: [],
})
export class ConfirmationRemoveRoleComponent implements OnInit {
  @Input() roleName;
  @Input() selectedUser;
  @Output() action = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  submitAction(action) {
    this.action.emit(action);
  }
}
