import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';

import {NestedTreeControl} from '@angular/cdk/tree';
import {ArrayDataSource, SelectionModel} from '@angular/cdk/collections';
import {Scope} from 'src/app/features/assessors/classes/scope';

@Component({
  selector: 'app-tree-selection',
  templateUrl: './tree-selection.component.html',
})
export class TreeSelectionComponent implements OnInit {
  selectedItems: any = [];

  @Input() isView = false;
  @Input() formField = false;
  @Input() classificationCode: any;
  @Output() SelectedItems = new EventEmitter<any>();
  @Input() selectedInput: any;

  /** The selection for checklist */
  @Input() TREE_DATA: Scope[] = [];
  treeSelection = new SelectionModel(false /* multiple */);
  treeControl = new NestedTreeControl<Scope>((node) => node.child);
  treeDataSource = new ArrayDataSource(this.TREE_DATA);

  constructor() {
  }

  hasChild = (_: number, node: Scope) => !!node.child && node.child.length > 0;

  ngOnInit(): void {
    this.treeDataSource = new ArrayDataSource(this.TREE_DATA);
    console.log(this.treeControl);
    if (this.selectedInput) {
      this.updateTree();
    }
  }

  // [compareWith]="schemaCompare"
  // schemaCompare(o1, o2): boolean {
  //   return o1 && o2 ? o1 === o2.schema_token : o2.schema_token === o2.schema_token;
  // }

  updateTree() {
    this.treeDataSource['_data'].forEach((parent) => {
      if (this.selectedInput === 'all') {
        this.treeSelection.toggle(parent);
        if (parent.childs && parent.childs.length) {
          parent.childs.forEach((element) => {
            this.treeSelection.toggle(element);
          });
        }
      } else {
        if (parent.childs && parent.childs.length) {
          let childsCount = 0;
          this.selectedInput.forEach((relatedParent) => {
            if (typeof relatedParent === 'object' && relatedParent !== null) {
              if (relatedParent.id === parent.id) {
                if (parent.childs.length === relatedParent.childs.length) {
                  this.treeSelection.toggle(parent);
                }
                relatedParent.childs.forEach((child) => {
                  const itemFound = parent.childs.find(
                    (item) => child.id === item.id
                  );
                  if (itemFound) {
                    this.treeSelection.toggle(itemFound);
                  }
                });
              }
            } else {
              const itemFound = parent.childs.find(
                (item) => relatedParent === item.id
              );
              if (itemFound) {
                this.treeSelection.toggle(itemFound);
                childsCount = childsCount + 1;
              }
              if (childsCount === parent.childs.length) {
                this.treeSelection.select(parent);
              }
            }
          });
        }
      }
    });
    if (this.formField) {
      setTimeout(() => {
        this.SelectedItems.emit(this.treeSelection.selected);
      }, 10);
    }
  }

  // TREE   configrations //////////////////////////////////////////
  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: Scope): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every((child) => this.treeSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: Scope): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.treeSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: Scope): void {
    this.treeSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.treeSelection.isSelected(node)
      ? this.treeSelection.select(...descendants)
      : this.treeSelection.deselect(...descendants);
  }

  // END TREE   configrations //////////////////////////////////////////

  isInArray(parentObj, item) {
    return parentObj.some((obj) => obj.id === item.id);
  }

  selected(node) {
    this.SelectedItems.emit(this.treeSelection.selected);
  }

  selectScope(node) {
    this.SelectedItems.emit(this.treeSelection.selected);
  }
}
