import {CabListSortList} from '../enum/cabListSortList.enum';

export class CabListFilterModel {
  page_number: number;
  per_page: number;
  order_type: string = 'ASC';
  order_by: CabListSortList = 0;
  search_key: string;
  user_token: string;
}
