<cdk-tree
  *ngIf="TREE_DATA.length"
  [dataSource]="treeDataSource"
  [treeControl]="treeControl"
>
  <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="tree-node">
    <div (click)="selectScope(node)">
      {{ node.scope_name }}
    </div>
  </cdk-nested-tree-node>
  <cdk-nested-tree-node
    *cdkTreeNodeDef="let node; when: hasChild"
    class="tree-node"
  >
    <button
      [attr.aria-label]="'toggle ' + node.scope_name"
      cdkTreeNodeToggle
      mat-icon-button
    >
      <mat-icon class="mat-icon-rtl-mirror">
        {{
        treeControl.isExpanded(node)
          ? 'expand_more'
          : 'chevron_right'
        }}
      </mat-icon>
    </button>
    {{ node.scope_name }}
    <div [class.tree-invisible]="!treeControl.isExpanded(node)">
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>
</cdk-tree>
