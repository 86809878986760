<mat-form-field>
  <input
    [formControl]="date"
    [matDatepickerFilter]="onYearsFilter"
    [matDatepicker]="dp"
    [max]="maxDate"
    [min]="minDate"
    [required]="isRequired"
    matInput
    placeholder="Year"
    readonly
  />
  <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
  <mat-datepicker
    #dp
    (yearSelected)="chosenYearHandler($event, dp)"
    panelClass="example-month-picker"
    startView="multi-year"
  >
  </mat-datepicker>
  <mat-error *ngIf="date.errors?.required"> Is Required</mat-error>
</mat-form-field>
