import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'unixDate',
})
export class UnixDate extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value * 1000, 'dd MMM., yyyy hh:mm aa');
  }
}
