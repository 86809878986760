import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef,} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {PaymentAction} from '../../classes/paymentAction';
import {SharedService} from '../service/shared.service';

@Component({
  templateUrl: './payment-request-modal.component.html',
  styles: [],
})
export class PaymentRequestModalComponent implements OnInit {
  dataLoading = false;
  loading = false;
  paymentData;
  actionArray: any[] = [];

  PaymentActionModel: PaymentAction = new PaymentAction();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PaymentRequestModalComponent>,
    private sharedService: SharedService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.PaymentActionModel.request_token = this.data.item.request_token;
    this.onGetPaymentDetailsData();
  }

  onGetPaymentDetailsData() {
    this.dataLoading = true;

    this.sharedService
      .getPaymentDetailsByToken(this.PaymentActionModel)
      .subscribe(
        (res) => {
          this.paymentData = res.data;
          this.actionArray = res.action_array;
          this.dataLoading = false;
        },
        (err) => {
          console.log(err);
          this.dataLoading = false;
        }
      );
  }

  onTakeAnAction(apiLink) {
    this.loading = true;
    this.sharedService
      .paymentAction(this.PaymentActionModel, apiLink)
      .subscribe(
        (res) => {
          this.loading = false;
          this.dialogRef.close(true);
        },
        (err) => {
          this.loading = false;
          console.log(err);
        }
      );
  }
}
