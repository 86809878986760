<div [ngClass]="{ active: docsEditable }" class="files-preview-btn-container">
  <div class="row">
    <div
      *ngFor="let file of files; let i = index"
      [ngClass]="{ 'col-md-6': cols == 2 }"
      class="col-12"
    >
      <a
        (click)="onDocumentDownloader(file.download, file.name)"
        class="file-item"
      >
        <div class="file-details">
          <div class="file-icon">
            <img
              *ngIf="fileType(file.name) == 'doc'"
              src="./assets/images/file-icons/file-doc-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'jpg'"
              src="./assets/images/file-icons/file-jpg-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'pdf'"
              src="./assets/images/file-icons/file-pdf-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'png'"
              src="./assets/images/file-icons/file-png-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'xls'"
              src="./assets/images/file-icons/file-xls-icon.svg"
            />
            <img
              *ngIf="fileType(file.name) == 'zip'"
              src="./assets/images/file-icons/file-zip-icon.svg"
            />
          </div>
          <div>
            <label [title]="labelName">{{
              labelName || 'Not Available'
            }}</label>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
