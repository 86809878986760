import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-photo-manager',
  templateUrl: './photo-manager.component.html',
  styles: [],
})
export class PhotoManagerComponent implements OnInit {
  @Output() selectedFiles = new EventEmitter<[]>();
  @Output() deletedFilesList = new EventEmitter<[]>();

  @Input() isRequired;
  @Input() Disapled;
  @Input() isSubmited;
  @Input() cols = 1;
  @Input() label;

  @Input() renderBtn = false;

  // Uploader
  @Input() isUploader = false;
  uploadedFilesArr: any = [];

  // DnD setting
  @Input() files: any = [];
  @Input() docsEditable = true;
  @Input() maxFiles;
  @Input() managerIndex: any = '0'; // in add numbertab_numberneeded, in details numbertab_d_numberneeded in "''"

  @Input() allowedFiles: any = [];

  loading = false;
  selectedfilesCount = 0;
  deletedFiles: any = [];
  validFile = true;
  fileMaxSiZeErorr = false;
  fileMaxNameSiZeErorr = false;

  message;
  urls: any = [];

  constructor() {
  }

  ngOnInit() {
  }

  fileType(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      default:
        return false;
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
    // let files = [];
    // files.push($event);

    // console.log(files);
    // files.forEach((element, i) => {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(element[i]);
    //   reader.onload = () => {
    //     this.urls.push(reader.result);
    //   };
    // });

    // console.log(this.urls);
    let files = $event;
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event) {
    this.prepareFilesList(event.target.files);
    event.target.value = '';
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number, id) {
    // if (id) {
    //   this.deletedFiles.push(id);
    // }

    // const deleted = this.files[index];
    this.urls.splice(index, 1);
    this.files.splice(index, 1);

    // if (this.isUploader) {
    //   const deletedIndx = this.uploadedFilesArr.findIndex(
    //     (item) => item.file_name === deleted.name
    //   );
    //   this.uploadedFilesArr.splice(deletedIndx, 1);
    //   this.selectedFiles.emit(this.uploadedFilesArr);
    // } else {
    //   this.selectedFiles.emit(this.files);
    //   this.deletedFilesList.emit(this.deletedFiles);
    // }
    this.selectedfilesCount = this.files.length;
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    const filesToUpload = [];
    this.validFile = true;
    this.selectedfilesCount = this.files.length + files.length;
    if (this.selectedfilesCount > this.maxFiles) {
      return;
    }
    for (const item of files) {
      if (this.fileType(item.name)) {
        this.validFile = true;
      } else {
        this.validFile = false;
      }

      // Check file and push to array files
      if (item.size / (1024 * 1024) <= 10 && this.fileType(item.name)) {
        if (files) {

          // for (let file of files) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
              this.urls.push(e.target.result);
            };
            reader.readAsDataURL(item);
          // }
        }
        item.fileType = this.fileType(item.name);
        this.files.push(item);
        filesToUpload.push(item);
        this.selectedFiles.emit(this.files);
        if (item.size / (1024 * 1024) <= 10) {
          this.fileMaxSiZeErorr = false;
        }
      } else {
        if (item.size / (1024 * 1024) > 10) {
          this.fileMaxSiZeErorr = true;
        }
      }
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
